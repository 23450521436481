import 'regenerator-runtime/runtime'

import { loadBlock } from '@bahn-wordpress/common/src/js/utils/block-loader'
import { loadVueComponent } from '@bahn-wordpress/common/src/js/utils/vue-loader'
import { requireAll } from '@bahn-wordpress/common/src/js/utils/require-assets'
import MainNavigation from './blocks/main-navigation'
import ImageReferences from './blocks/image-references'
// import objectFitImages from 'object-fit-images'

// import all svg-assets
requireAll(require.context('./../assets/svg/', true, /\.svg$/))

// eslint-disable-next-line
__webpack_public_path__ = '/wp-content/themes/dbec/dist/'

// init blocks:
// image-references must be rendered before any gallery-init, to preserve a consistent order
new MainNavigation('.main-navigation')
new ImageReferences('.entry-content')

const entryContent = document.body.querySelector('main.content-wrapper')

loadBlock(
    () => import(/* webpackChunkName: 'gallery' */ './blocks/gallery'),
    '.wp-block-dbec-core-gallery, .wp-block-dbec-core-header-gallery',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'counter-facts' */ './blocks/counter-facts'
        ),
    '.counter-facts',
    entryContent
)

loadBlock(
    () =>
        import(/* webpackChunkName: 'block-accordion' */ './blocks/accordion'),
    '.accordion',
    entryContent
)

loadBlock(
    () => import(/* webpackChunkName: 'breadcrumbs' */ './blocks/breadcrumbs'),
    'nav.breadcrumbs',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'centered-headline' */ './blocks/centered-headline'
        ),
    '.centered-headline',
    entryContent
)

loadBlock(
    () => import(/* webpackChunkName: 'wpcf7' */ './blocks/wpcf7'),
    '.wpcf7',
    entryContent
)

loadBlock(
    () => import(/* webpackChunkName: 'video-slide' */ './blocks/video-slide'),
    '.video-standalone',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'social-sharing' */ './blocks/social-sharing'
        ),
    '.social-sharing',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'layout-hidden-content' */ './blocks/layout-hidden-content'
        ),
    '.layout-hidden-content',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'form-register' */ './blocks/form-register'
        ),
    '.form-register',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'newsletter-cleverreach' */ './blocks/newsletter-cleverreach'
        ),
    '.newsletter-cleverreach',
    entryContent
)

loadBlock(
    () => import(/* webpackChunkName: 'lightbox' */ './blocks/lightbox'),
    '.lightbox'
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'animations' */ './blocks/animation-base-cards'
        ),
    '.layout-linked-items',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'animations' */ './blocks/animation-base-cards'
        ),
    '.news',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'animations' */ './blocks/animation-base-cards'
        ),
    '.related-posts',
    entryContent
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'animations' */ './blocks/animation-base-cards'
        ),
    '.list-child-pages',
    entryContent,
    { thresholdMediumUp: 0.25 }
)

loadBlock(
    () =>
        import(
            /* webpackChunkName: 'animations' */ './blocks/animation-base-cards'
        ),
    '.animated-base-cards',
    entryContent,
    { thresholdMediumUp: 0.2 }
)

loadBlock(
    () => import(/* webpackChunkName: 'lightbox' */ './blocks/enlarge-image'),
    '.enlarge-image',
    entryContent
)

loadBlock(
    () => import(/* webpackChunkName: 'lightbox' */ './blocks/audio-player'),
    '.audio-player',
    entryContent
)

loadBlock(
    () => import(/* webpackChunkName: 'text-image-teaser' */ './blocks/text-image-teaser-animated'),
    '.text-image-teaser--fade-in-animated',
    entryContent,
)

// --- VUE STUFF ---

loadVueComponent(
    '#list-posts',
    () =>
        import(
            /* webpackChunkName: 'list-posts' */ './components/ListPosts.vue'
        ),
    '.list-posts',
    'ListPosts'
)

loadVueComponent(
    '#search',
    () => import(/* webpackChunkName: 'search' */ './components/Search.vue'),
    '.search',
    'Search'
)
